// src/componetns/Footer.tsx

import React, { FC, ReactElement, useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import Ambassador from "../images/qlik_ambassador_badge.png";
import Elite from "../images/qlik_elite_badge.jpg";
import { useAppContext } from "../contexts/AppContext";

export const Footer: FC = (): ReactElement => {
  const [text, setText] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { settings } = useAppContext();

  useEffect(() => {
    if (settings) {
      setText(settings?.footer);
      setEmail(settings?.email);
    }
  }, [settings]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "#F7F7F7",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box p={1}>
                <img
                  src={Ambassador}
                  title="Ambassador Program"
                  alt="Ambassador Program"
                  style={{ width: "50px", height: "50px" }}
                />
              </Box>
              <Box p={1}>
                <img
                  src={Elite}
                  title="Qlik Elite Partner"
                  alt="Qlik Elite Partner"
                  style={{ width: "125px", height: "50px" }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography color="text.primary" sx={{ fontSize: "0.7rem" }}>
              {`${new Date().getFullYear()} | ${text}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="text.primary"
              variant="subtitle1"
              sx={{ fontSize: "0.7rem", fontWeight: 500 }}
            >
              {email}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
