import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";

import { useAppContext } from "../contexts/AppContext";

export default function Submit() {
  const [app, setApp] = React.useState("");
  const { demoUrl, activeEnvironment } = useAppContext();

  useEffect(() => {
    setApp(demoUrl || "");
  }, [demoUrl]);

  useEffect(() => {
    setApp("");
  }, [activeEnvironment]);

  const handleSubmit = (event: any) => {
    window.open(app, "_blank");
  };

  const { classes } = useStyles();

  return (
    <Button
      classes={{ disabled: classes.disabled }}
      disabled={app === ""}
      sx={{
        width: "100px",
        borderRadius: "4px",
        fontSize: "1.1rem",
        padding: "2px !important",
        backgroundColor: "secondary.main",
        color: "secondary.contrastText",
        "&:hover": {
          backgroundColor: "secondary.main",
          color: "secondary.contrastText",
        },
        "&:focus": {
          backgroundColor: "secondary.main",
          color: "secondary.contrastText",
        },
      }}
      onClick={handleSubmit}
    >
      GO
    </Button>
  );
}

const useStyles = makeStyles()((theme: any) => ({
  disabled: {
    color: theme.palette.primary.light,
    background: "white !important",
    border: `1px solid ${theme.palette.text.secondary}`,
  },
}));
