import { useContext, createContext } from "react";

export type AppContextType = {
  settings?: any;
  demoUrl?: string;
  activeEnvironment?: string;
  setDemoUrl?: (url: string) => void;
  setActiveEnvironment?: (environment: string) => void;
};

export const AppContext = createContext<AppContextType>({
  settings: null,
  demoUrl: "",
  activeEnvironment: "",
  setDemoUrl: undefined,
  setActiveEnvironment: undefined,
});

export const useAppContext = () => useContext(AppContext);
