// @ts-nocheck

import React, { FC, ReactElement } from "react";
import Cube from "react-3d-cube";

import Left from "../images/Left.png";
import Right from "../images/Right.png";
import Top from "../images/Top.png";
import Back from "../images/Back.png";
import Front from "../images/Front.png";
import Bottom from "../images/Bottom.png";
import "../css/Cube.css";

const Cube3D: FC = (): ReactElement => {
  return (
    <div
      style={{
        width: 350,
        height: 350,
        textAlign: "center",
      }}
    >
      <Cube size={350} index="top">
        <div style={{ backgroundColor: "white" }}>
          <img
            src={Bottom}
            alt="Bottom"
            style={{ width: "350px", height: "350px" }}
          />
        </div>
        <div style={{ backgroundColor: "white" }}>
          <img
            src={Right}
            alt="Right"
            style={{ width: "350px", height: "350px" }}
          />
        </div>
        <div style={{ backgroundColor: "white" }}>
          <img
            src={Back}
            alt="Back"
            style={{ width: "350px", height: "350px" }}
          />
        </div>
        <div style={{ backgroundColor: "white" }}>
          <img
            src={Left}
            alt="Left"
            style={{ width: "350px", height: "350px" }}
          />
        </div>
        <div style={{ backgroundColor: "white" }}>
          <img
            src={Top}
            alt="Top"
            style={{ width: "350px", height: "350px" }}
          />
        </div>
        <div style={{ backgroundColor: "white" }}>
          <img
            src={Front}
            alt="Front"
            style={{ width: "350px", height: "350px" }}
          />
        </div>
      </Cube>
    </div>
  );
};

export default Cube3D;
