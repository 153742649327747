import React, { FC, useState } from "react";

import { AppContextType, AppContext } from "./AppContext";

interface Props {
  settings?: any;
  value?: AppContextType;
  children?: React.ReactNode;
}

const AppProvider: FC<Props> = ({ settings, value = {}, children }) => {
  const [demoUrl, setDemoUrl] = useState<string>("");
  const [activeEnvironment, setActiveEnvironment] = useState<string>("");
  return (
    <AppContext.Provider
      value={{
        settings,
        demoUrl,
        activeEnvironment,
        setDemoUrl,
        setActiveEnvironment,
        ...value,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
