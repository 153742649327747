import * as React from "react";

function SvgLogoSiemens(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="_150_Y_ZURICH_LOGO_HORIZONTAL"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100"
      height="20px"
      viewBox="0 0 810 120"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            fill="#2167AE"
            d="M414,5.8c-29.8,0-54,24.2-54,54l0,0c0,11.8,3.9,23.3,11,32.8l44.9-59h-9.6c-4.5-0.1-9,0.3-13.4,1
			c-2,0.4-3.8,1.1-5.5,2.3c-2.3,1.8-4.1,4-5.5,6.6c-0.2,0.3-0.4,0.8-0.7,1.4l-2.6-0.6l4.4-17h56.2L394.6,87c2.1-0.2,4.3-0.3,6.4-0.3
			c1.8,0,4.5,0.1,8.2,0.3l8,0.5c2.9,0.2,5.2,0.2,7.1,0.2c3.2,0.2,6.3-0.2,9.4-1.1c2-0.6,4-1.6,5.8-2.8c3.4-2.4,6.1-5.6,8-9.2
			c0.2-0.3,0.5-0.9,0.8-1.6l2.4,0.6c-0.5,2.3-1.3,4.6-2.3,6.7c-2.4,5.4-5.1,8.9-8.8,11.7c-3.8,2.8-8.4,4.3-13.1,4.3h-0.8
			c-3.7,0-6.6-0.3-15.5-1.6c-9.1-1.3-13.7-1.8-18.2-1.8c-3,0-5.9,0.2-8.8,0.7c-3.1,0.4-6.1,1.3-8.9,2.7c20.2,22,54.4,23.5,76.3,3.3
			c22-20.2,23.5-54.4,3.3-76.3C443.6,12.2,429.1,5.8,414,5.8z"
          ></path>
          <path
            fill="#2167AE"
            d="M692.1,43.3c0-3.5,0.1-4.5,0.5-5.9c0.2-0.5,0.5-1.1,0.8-1.6c0.5-0.9,1.2-1.6,2-2.2h-16.9
			c0.8,0.5,1.4,1.3,2,2.1c0.3,0.5,0.6,1.1,0.8,1.7c0.4,1.3,0.5,2.5,0.5,5.6c0,1.6,0,2.7,0,3.2v31.5c0,1.7-0.2,3.4-0.9,5.1
			c-0.2,0.5-0.5,1.1-0.9,1.6c-0.5,0.7-1,1.3-1.6,1.9h16.7c-0.6-0.5-1.2-1.2-1.6-1.9c-0.3-0.5-0.6-1-0.9-1.6
			c-0.5-1.5-0.7-3.1-0.7-4.6V46.4L692.1,43.3z"
          ></path>
          <path
            fill="#2167AE"
            d="M804.6,43.3c-0.1-1.9,0.2-3.8,0.5-5.6c0.2-0.7,0.5-1.3,0.9-2c0.5-0.9,1.3-1.6,2.1-2.2h-17.2
			c0.8,0.5,1.4,1.3,2,2.1c0.4,0.5,0.6,1.2,0.9,1.8c0.5,1.8,0.6,3.7,0.5,5.6v12.8h-23.6v-9.6c0-2.9,0-4.8,0.2-6.3
			c0.1-0.8,0.2-1.6,0.4-2.3c0.5-1.3,1.3-2.6,2.2-3.6c0.1-0.2,0.3-0.3,0.5-0.5H757c0.6,0.6,1.3,1.3,1.7,2c0.4,0.5,0.7,1.2,0.9,1.8
			c0.5,1.3,0.5,2,0.5,5.6l0.1,3.4v28.8c0.1,1.9,0,3.7-0.2,5.6c-0.1,0.7-0.3,1.4-0.5,2.1c-0.5,1.1-1.1,2.1-2,3
			c-0.2,0.1-0.3,0.3-0.5,0.5h16.7c-0.5-0.5-1-1.1-1.4-1.7c-0.3-0.5-0.6-1.1-0.9-1.6c-0.5-1.5-0.8-3.1-0.8-4.6c0-1.1,0-2.2,0-3.1
			V62.5h23.6v12.8c0.1,1.8,0,3.6-0.2,5.3c-0.1,0.8-0.2,1.6-0.5,2.3c-0.4,1-1.1,2-1.9,2.7c-0.2,0.2-0.4,0.4-0.7,0.6h16.8
			c-0.5-0.5-1-1-1.4-1.6c-0.4-0.5-0.7-1.1-0.9-1.7c-0.5-1.3-0.6-2.2-0.7-4.8c0-1.6,0-2.4,0-2.7V46.4L804.6,43.3z"
          ></path>
          <path
            fill="#2167AE"
            d="M670.1,82.6c-1.9-1.7-3.5-3.4-5.9-6.1c-1.6-1.6-2.8-3.1-3.8-4.3l-8.2-9.6c0.8-0.2,1.6-0.5,2.3-0.8
			c2.3-0.9,4.5-2.1,6.5-3.8c3.1-2.4,4.8-6.2,4.8-10.1c0-3.2-1.3-6.3-3.5-8.6c-2.3-2.3-5.3-4-8.5-4.8c-3-0.6-5.9-0.9-9-0.9
			c-1.6,0-3.2-0.1-4.8-0.1h-13.6c0.7,0.5,1.3,1.2,1.8,1.9c0.4,0.6,0.7,1.3,0.9,1.9c0.4,1.8,0.6,3.8,0.5,5.6v34.6
			c0.1,1.7-0.1,3.4-0.6,5.1c-0.2,0.6-0.5,1.2-0.9,1.6c-0.5,0.7-1.1,1.3-1.8,1.8h16.7c-0.5-0.5-1-1.1-1.5-1.7c-0.4-0.5-0.6-1-0.9-1.6
			c-0.5-1.6-0.8-3.2-0.6-4.8c0-0.8,0-1.6,0-2.7V63.3l9.8,11.7c0.5,0.5,1.2,1.4,2.2,2.6c1.2,1.3,2,2.3,2.6,2.8
			c1.6,1.7,3.4,3.1,5.4,4.3c1.6,0.9,3.4,1.3,5.2,1.3c0.9,0,1.8,0.1,2.7,0.1h6.6c-0.9-0.7-1.3-1-2-1.6S670.9,83.4,670.1,82.6z
			 M650,56.8c-2,1.2-4.3,1.9-6.6,2.1c-0.9,0.2-1.8,0.2-2.7,0.2c-0.2,0-0.5,0-0.9,0V39.6c0.7,0,0.9,0,1.4,0c2.3,0,4.8,0.2,7,0.9
			c1.3,0.4,2.5,1,3.6,1.9c1.9,1.6,2.9,4,2.8,6.4c0.1,2.2-0.6,4.3-2,5.9C651.8,55.6,651,56.3,650,56.8L650,56.8z"
          ></path>
          <path
            fill="#2167AE"
            d="M742.6,78.7c-2.9,1.3-6,2-9.2,2c-5.1,0.2-10.1-2-13.5-5.6c-3.4-3.7-5.2-8.8-5.2-14.5c0-5.5,1.7-10.6,4.8-14.2
			c1.3-1.6,3-2.9,4.8-3.9c3.1-1.7,6.6-2.6,10.2-2.5c3.2,0,6.5,0.6,9.5,1.9c1.6,0.5,3,1.3,4.3,2.3v-9.9c-1-0.4-2.1-0.6-3.2-0.9
			c-3.1-0.6-6.2-0.9-9.3-0.9c-5.2-0.1-10.2,0.9-15,2.8c-2.6,1.1-5,2.5-7.3,4.3c-6.5,5.2-10,12.7-10,21.5c0,8.4,3.4,15.8,9.6,20.6
			c5.1,4.1,11.7,6.1,19.6,6.1c5,0,10-0.8,14.8-2.3c0.2-0.1,0.5-0.2,0.9-0.3v-9.6c-1.3,0.9-1.7,1.1-2.4,1.6
			C744.8,77.7,743.7,78.2,742.6,78.7z"
          ></path>
          <path
            fill="#2167AE"
            d="M602.7,35.7c0.3,0.5,0.6,1.2,0.8,1.8c0.4,1.3,0.5,2.6,0.5,5.5v20c0.1,3-0.1,5.9-0.5,8.9
			c-0.2,1.3-0.6,2.7-1.3,3.8c-1.5,2.3-3.8,3.9-6.5,4.5c-1.3,0.3-2.5,0.5-3.8,0.5c-4.5,0-8.4-1.7-10.2-4.5c-1.5-2.2-1.9-4.2-2-9.6
			v-4.4V46.4c0-3.1,0-4.5,0.2-6.1c0-0.8,0.2-1.6,0.4-2.4c0.5-1.4,1.2-2.7,2.1-3.8c0.2-0.1,0.2-0.2,0.5-0.5h-17.1
			c0.8,0.5,1.4,1.3,1.9,2.1c0.3,0.5,0.6,1.2,0.8,1.8c0.2,0.8,0.3,1.6,0.4,2.4c0,0.5,0,1.6,0,3.3v3.1v15.2c0,5.6,0,7,0.2,9.2
			c0.2,3.5,1.5,7,3.7,9.7c4,5,10.4,7.7,18.5,7.7c3.6,0.1,7.2-0.5,10.6-1.6c3-1.1,5.6-2.9,7.8-5.2c2.5-2.8,4-6.4,4.2-10.2
			c0.3-3.1,0.4-6.3,0.4-9.4V46.3v-3v-0.6c-0.1-1.6,0.1-3.2,0.5-4.8c0.5-1.7,1.5-3.3,3-4.3h-16.8C601.5,34.2,602.2,34.9,602.7,35.7z"
          ></path>
          <path
            fill="#2167AE"
            d="M545,78.8c-2.7,0.5-5.5,0.7-8.3,0.8c-1.1,0-2.5,0-4.2,0h-8.1l31.9-46H515l-5.1,11.2c1.3-0.7,2.5-1.3,3.8-1.8
			c2.4-0.9,4.9-1.6,7.4-2.1c2.4-0.4,4.9-0.6,7.4-0.6c1.5-0.1,2.8-0.1,4.1-0.1h6.2l-32.2,46h45.5l5.2-12c-1.3,0.8-2.5,1.4-3.8,2
			C550.8,77.5,548,78.4,545,78.8z"
          ></path>
        </g>
        <rect x="278" fill="#2167AE" width="3" height="120"></rect>
        <g>
          <path
            fill="#2167AE"
            d="M15.4,26c-3.9,2.9-8.6,4.6-13.5,4.8v9.8c4.3-0.2,8.5-1.2,12.4-3c2.9-1.3,5.5-2.9,7.9-5v73.1h10.6V13.9h-8.9
			C22.4,18.8,19.4,23,15.4,26z"
          ></path>
          <path
            fill="#2167AE"
            d="M95.3,48.8c-4.9-2.7-10.3-4-15.9-3.8c-4.1-0.1-8.2,0.6-12.2,2c-3.4,1.3-6.5,3.1-9.3,5.5l3.4-29.1h43.9v-9.5
			H52.2l-5.6,50.4h10c2.3-2.9,5.1-5.3,8.2-7.2c3.2-2,7.4-3.1,12.7-3.1c6.5,0,11.7,2,15.7,5.9C97,63.9,99,69.1,99,75.6
			c0,6.9-2,12.3-6.1,16.3c-4.1,4-9.5,6-16.5,6c-6,0-11-1.6-14.9-4.9c-3.9-3.1-6.4-7.7-7.1-12.7h-11c0.5,5,2.2,9.6,5,13.8
			c2.9,4.2,6.9,7.6,11.4,9.9c4.8,2.4,10.3,3.7,16.5,3.7c6.1,0.1,12.1-1.3,17.5-4.1c4.9-2.5,9-6.5,11.7-11.3
			c2.8-5.1,4.2-10.8,4.1-16.6c0.1-5.6-1.2-11.1-3.8-16.1C103.5,55.1,99.8,51.4,95.3,48.8z"
          ></path>
          <path
            fill="#2167AE"
            d="M184.3,78.8c1.5-6.4,2.2-12.9,2.2-19.4c0-9.1-1.3-17.3-4-24.4s-6.6-12.8-12-16.9c-5.4-4.1-12-6.2-19.6-6.2
			c-7.9,0-14.5,2-19.9,6.2c-5.4,4.1-9.4,9.8-12.2,16.9c-2.7,7.1-4.1,15.3-4.1,24.4c-0.1,6.6,0.7,13.1,2.2,19.4h10.9
			c-1.7-5.5-2.5-11.9-2.5-19.4c0-11.9,2.2-21.2,6.7-27.8c4.5-6.6,10.7-10,18.7-9.9c7.8,0,13.9,3.4,18.4,9.9
			c4.4,6.6,6.6,15.9,6.6,27.8c0,7.6-0.8,14-2.4,19.4L184.3,78.8L184.3,78.8z"
          ></path>
          <path
            fill="#2167AE"
            d="M128.4,96.5l-4.8-12.1h-4.3l7,17l-0.6,1.4c-0.1,0.3-0.3,0.7-0.6,1c-0.3,0.2-0.8,0.3-1.2,0.3h-2.4v3.8h2.4
			c1.2,0.1,2.4-0.3,3.4-0.9c1-0.8,1.8-1.8,2.2-3.1l8-19.4h-4.3L128.4,96.5z"
          ></path>
          <path
            fill="#2167AE"
            d="M150.7,85c-1.3-0.8-2.9-1.2-4.4-1.1c-1.6-0.1-3.2,0.3-4.6,1.2c-1.3,0.8-2.3,1.9-3,3.3c-0.7,1.5-1,3-1,4.6
			s0.3,3.2,1,4.7c0.7,1.3,1.7,2.4,3,3.2c1.4,0.8,3,1.2,4.6,1.1c1.8,0.1,3.6-0.5,5-1.5c1.5-1,2.4-2.6,2.7-4.3h-4
			c-0.2,0.7-0.7,1.3-1.3,1.7c-0.7,0.4-1.5,0.7-2.4,0.6c-1.2,0.1-2.4-0.3-3.3-1.1c-0.9-1-1.4-2.2-1.4-3.5h12.7c0-0.3,0-0.6,0-1
			c0.1-1.7-0.3-3.3-0.9-4.8C152.8,86.9,151.9,85.7,150.7,85z M141.9,91.1c0.1-1,0.7-2,1.5-2.8c0.8-0.6,1.8-1,2.9-1
			c1-0.1,2,0.3,2.7,1c0.7,0.8,1.1,1.7,1.1,2.8H141.9z"
          ></path>
          <path
            fill="#2167AE"
            d="M168,84.6c-2.4-1-5.1-1-7.6-0.1c-1,0.4-2,1.2-2.6,2.1c-0.7,1-1.1,2.1-1.2,3.2h4.2c0.1-0.7,0.4-1.3,1-1.7
			c0.6-0.5,1.5-0.7,2.2-0.6c0.8-0.1,1.6,0.2,2.2,0.7c0.6,0.5,0.8,1.2,0.8,1.9v1.3c-1.9,0-3.8,0.1-5.6,0.5c-1.4,0.2-2.7,0.8-3.8,1.7
			c-1,0.9-1.5,2.2-1.5,3.6c-0.1,1.3,0.6,2.6,1.5,3.4c1.2,0.9,2.7,1.4,4.2,1.3c1,0,2.1-0.3,3.1-0.8c0.8-0.6,1.5-1.3,2-2.2v2.7h4.1
			V90.2c0-1.1-0.3-2.2-0.8-3.2C169.9,85.9,169.1,85.1,168,84.6z M167.2,94.2c0,0.8-0.1,1.5-0.5,2.2c-0.3,0.6-0.8,1.2-1.4,1.5
			c-0.6,0.3-1.3,0.6-2,0.6c-0.7,0-1.4-0.1-2-0.5c-0.5-0.3-0.7-0.9-0.7-1.5c0-0.7,0.3-1.3,0.8-1.7c0.7-0.4,1.5-0.7,2.3-0.8
			c1.2-0.1,2.4-0.2,3.5-0.2L167.2,94.2z"
          ></path>
          <path
            fill="#2167AE"
            d="M180.6,85c-0.6,0.5-1.2,1.1-1.5,1.9v-2.5h-4.3v17.1h4.3v-8.7c-0.1-1.2,0.3-2.3,1-3.2c0.7-0.8,1.7-1.3,2.7-1.2
			h1.7v-4h-1.7C182.1,84.4,181.3,84.6,180.6,85z"
          ></path>
          <path
            fill="#2167AE"
            d="M197.8,92c-1.2-0.5-2.4-0.8-3.6-1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.9-0.1-1.7-0.4-2.6-0.8
			c-0.4-0.2-0.7-0.6-0.7-1.1c0-0.6,0.3-1,0.8-1.3c0.6-0.3,1.3-0.6,2-0.5c0.7-0.1,1.4,0.2,2,0.6c0.5,0.4,0.8,1,0.9,1.7h4.1
			c-0.1-1.6-0.9-3.1-2.2-4.1c-1.4-1-3.1-1.5-4.9-1.5c-1.3,0-2.5,0.2-3.7,0.7c-1,0.4-1.8,1-2.4,2c-0.6,0.8-0.8,1.8-0.8,2.9
			c-0.1,0.9,0.2,1.8,0.7,2.6c0.5,0.7,1.2,1.3,2,1.6c1,0.5,2.2,0.8,3.4,1l0.6,0.1l0.6,0.1c0.8,0.1,1.5,0.3,2.3,0.7
			c0.4,0.2,0.6,0.7,0.6,1.1c0,0.6-0.3,1.2-0.8,1.5c-0.6,0.4-1.4,0.6-2.2,0.6c-0.8,0.1-1.7-0.2-2.4-0.7c-0.6-0.5-1-1.1-1-1.9h-4.2
			c0.1,1.7,0.9,3.2,2.2,4.3c1.6,1.1,3.5,1.7,5.5,1.6c1.3,0,2.7-0.2,3.9-0.7c1-0.4,1.9-1.1,2.5-2c0.6-0.9,0.9-2,0.9-3
			c0.1-1-0.2-1.9-0.8-2.7C199.3,92.9,198.6,92.3,197.8,92z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoSiemens;
