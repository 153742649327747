// src/pages/Home.tsx

import React, { ReactElement, FC } from "react";
import { Box } from "@mui/material";
import Login from "../components/Login";

const Home: FC<any> = (): ReactElement => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#F7F7F7",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Login />
    </Box>
  );
};

export default Home;
