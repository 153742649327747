import * as React from "react";

function SvgLogoSiemens(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="_Ebene_"
      data-name="&lt;Ebene&gt;"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 144"
      width="100"
      height="20px"
    >
      <title>sh_logo_rgb</title>
      <g>
        <g>
          <path
            fill="#ec6602"
            d="M551.905,82.821a11.18,11.18,0,1,0-3.278-7.909,11.146,11.146,0,0,0,3.278,7.909"
          />
          <path
            fill="#ec6602"
            d="M533.143,101.583a8.61,8.61,0,1,0-2.519-6.083,8.572,8.572,0,0,0,2.519,6.083"
          />
          <path
            fill="#ec6602"
            d="M533.165,60.43a8.609,8.609,0,1,0-2.519-6.083,8.574,8.574,0,0,0,2.519,6.083"
          />
          <path
            fill="#ec6602"
            d="M513.959,120.767a6.62,6.62,0,1,0-1.938-4.68,6.6,6.6,0,0,0,1.938,4.68"
          />
          <path
            fill="#ec6602"
            d="M513.982,79.614a6.617,6.617,0,1,0-1.939-4.68,6.6,6.6,0,0,0,1.939,4.68"
          />
          <path
            fill="#ec6602"
            d="M514.005,38.462a6.62,6.62,0,1,0-1.938-4.68,6.6,6.6,0,0,0,1.938,4.68"
          />
          <path
            fill="#ec6602"
            d="M494.474,99.122a5.09,5.09,0,1,0-1.491-3.6,5.071,5.071,0,0,0,1.491,3.6"
          />
          <path
            fill="#ec6602"
            d="M494.5,57.97a5.09,5.09,0,1,0-1.491-3.6,5.074,5.074,0,0,0,1.491,3.6"
          />
          <path
            fill="#ec6602"
            d="M474.74,77.727a3.915,3.915,0,1,0-1.148-2.77,3.9,3.9,0,0,0,1.148,2.77"
          />
        </g>
        <g>
          <path
            fill="#099"
            d="M145.576,54.6V44.936a49.342,49.342,0,0,0,14.6,2.6q8.763,0,8.763-4.629A3.8,3.8,0,0,0,167.66,40q-1.314-1.248-6.694-3.455-9.651-3.971-12.589-6.771a12.484,12.484,0,0,1-3.792-9.332q0-7.246,5.529-11.049,5.465-3.768,14.24-3.768a79.365,79.365,0,0,1,13.994,1.781V16.7A34.265,34.265,0,0,0,165.7,13.983q-8.229,0-8.229,4.528a3.2,3.2,0,0,0,1.661,2.762q1.379.86,7.6,3.662,8.952,3.971,11.92,6.911a12.15,12.15,0,0,1,3.528,9.023A13.359,13.359,0,0,1,175.259,53q-5.595,3.386-14.515,3.383a66.52,66.52,0,0,1-15.168-1.78"
          />
          <path fill="#099" d="M189.889,55.388h13.662V6.475H189.889V55.388Z" />
          <polygon
            fill="#099"
            points="216.079 6.477 216.079 55.39 251.681 55.39 251.681 46.044 229.246 46.044 229.246 34.437 248.284 34.437 248.284 26.371 229.246 26.371 229.246 15.327 251.118 15.327 251.118 6.477 216.079 6.477"
          />
          <polygon
            fill="#099"
            points="303.239 6.477 290.621 37.728 278.316 6.477 260.601 6.477 260.601 55.39 270.226 55.39 270.226 20.76 284.293 55.885 292.767 55.885 307.113 20.76 307.113 55.39 320.063 55.39 320.063 6.477 303.239 6.477"
          />
          <polygon
            fill="#099"
            points="332.591 6.477 332.591 55.39 368.198 55.39 368.198 46.044 345.758 46.044 345.758 34.437 364.8 34.437 364.8 26.371 345.758 26.371 345.758 15.327 367.63 15.327 367.63 6.477 332.591 6.477"
          />
          <polygon
            fill="#099"
            points="409.819 6.477 409.819 39.22 393.024 6.477 377.187 6.477 377.187 55.39 386.812 55.39 386.812 22.208 404.059 55.39 419.444 55.39 419.444 6.477 409.819 6.477"
          />
          <path
            fill="#099"
            d="M429.143,54.6V44.936a48.846,48.846,0,0,0,14.6,2.6q8.765,0,8.764-4.629A3.831,3.831,0,0,0,451.26,40q-1.306-1.248-6.723-3.455-9.622-3.942-12.589-6.771a12.451,12.451,0,0,1-3.8-9.365q0-7.219,5.53-11.016,5.457-3.768,14.244-3.768A67.97,67.97,0,0,1,460.707,7.2l1.208.207V16.7a34.351,34.351,0,0,0-12.686-2.718q-8.193,0-8.19,4.528a3.2,3.2,0,0,0,1.65,2.762q1.314.823,7.638,3.662,8.886,3.971,11.886,6.911a12.135,12.135,0,0,1,3.528,9.023A13.38,13.38,0,0,1,458.864,53q-5.631,3.386-14.548,3.383a66.5,66.5,0,0,1-15.173-1.78"
          />
        </g>
        <g>
          <path
            fill="#ec6602"
            d="M343.319,105.474c0,5.814-7.844,7.166-13.32,7.334.25-5.818,3.287-11.468,9.018-11.468a3.945,3.945,0,0,1,4.3,4.134m10.284-.086c0-8.008-5.481-12.31-13.994-12.31-13.826,0-20.992,11.3-20.992,23.691,0,12.647,5.732,21.583,20.063,21.583a26.592,26.592,0,0,0,15.429-4.721l-3.374-7.839a18.816,18.816,0,0,1-10.534,3.624c-6.41,0-9.11-4.3-9.7-8.345,10.2-.423,23.1-2.955,23.1-15.683"
          />
          <path
            fill="#ec6602"
            d="M384.207,105.474c0,5.814-7.844,7.166-13.32,7.334.25-5.818,3.287-11.468,9.018-11.468a3.945,3.945,0,0,1,4.3,4.134m10.285-.086c0-8.008-5.482-12.31-14-12.31-13.826,0-20.991,11.3-20.991,23.691,0,12.647,5.731,21.583,20.062,21.583A26.592,26.592,0,0,0,395,133.631l-3.374-7.839a18.813,18.813,0,0,1-10.534,3.624c-6.41,0-9.105-4.3-9.7-8.345,10.2-.423,23.1-2.955,23.1-15.683"
          />
          <path
            fill="#ec6602"
            d="M80.035,105.474c0,5.814-7.844,7.166-13.321,7.334.25-5.818,3.287-11.468,9.019-11.468a3.945,3.945,0,0,1,4.3,4.134m10.284-.086c0-8.008-5.481-12.31-13.994-12.31-13.826,0-20.992,11.3-20.992,23.691,0,12.647,5.732,21.583,20.063,21.583a26.584,26.584,0,0,0,15.428-4.721l-3.373-7.839a18.816,18.816,0,0,1-10.535,3.624c-6.41,0-9.109-4.3-9.7-8.345,10.2-.423,23.1-2.955,23.1-15.683"
          />
          <polygon
            fill="#ec6602"
            points="34.254 81.024 34.254 103.363 17.055 103.363 17.055 81.024 5 81.024 5 137.088 17.055 137.088 17.055 113.902 34.254 113.902 34.254 137.088 46.314 137.088 46.314 81.024 34.254 81.024"
          />
          <path
            fill="#ec6602"
            d="M123.281,123.43c0,3.118-2.526,6.323-6.91,6.323-6.242,0-7.676-5.987-7.676-12.815,0-8.263,2.363-15.174,9.278-15.174a13.992,13.992,0,0,1,5.308.924Zm12.479,13.657-.843-8.513V96.2A50.221,50.221,0,0,0,119.4,93.078c-14.918,0-22.339,9.7-22.339,24.533,0,11.382,4.129,20.655,16.353,20.655,4.557,0,8.768-1.684,11.3-5.563l.424.087.587,4.3Z"
          />
          <path
            fill="#ec6602"
            d="M145.539,81.107v43.249c0,7.084.674,13.994,12.815,13.994a22.35,22.35,0,0,0,10.708-2.695l-2.532-7.844a14.519,14.519,0,0,1-5.139,1.353c-3.124,0-4.134-1.858-4.134-6.492V81.107Z"
          />
          <path
            fill="#ec6602"
            d="M218.631,137.087v-27.57A7.162,7.162,0,0,1,225.8,102.1c4.47,0,5.394,3.369,5.394,7.753v27.233h11.718v-29c0-10.034-4.8-15.01-13.657-15.01a14.365,14.365,0,0,0-10.621,4.384V81.023H206.913v56.064Z"
          />
          <path
            fill="#ec6602"
            d="M253.277,137.088H265V94.344H253.277v42.744Z"
          />
          <path
            fill="#ec6602"
            d="M252.739,80.987a5.883,5.883,0,0,0,1.724,4.346,6.528,6.528,0,0,0,4.638,1.683,6.67,6.67,0,0,0,4.643-1.683,6.049,6.049,0,0,0,0-8.6,6.592,6.592,0,0,0-4.643-1.775,6.463,6.463,0,0,0-4.607,1.775,5.907,5.907,0,0,0-1.755,4.255"
          />
          <path
            fill="#ec6602"
            d="M287.086,137.087v-27.4a7.289,7.289,0,0,1,7.334-7.585c4.471,0,5.227,3.455,5.227,8.09v26.9h11.718V107.915c0-10.116-5.058-14.837-13.4-14.837A14.945,14.945,0,0,0,285.234,99.4l-.847-5.058h-9.779l.76,7.926v34.817Z"
          />
          <path
            fill="#ec6602"
            d="M415.315,110.53c.168-4.466,2.868-7.334,7.084-7.334a12.816,12.816,0,0,1,5.981,1.516L430.488,94.6a15.14,15.14,0,0,0-7-1.515c-3.961,0-8.09,2.358-10.116,7.334l-1.1-6.074h-9.524l.843,7.421v35.323h11.718Z"
          />
          <path
            fill="#ec6602"
            d="M462.8,96.793l.033-.1a23.378,23.378,0,0,0-13.96-3.633c-8.663.452-15.472,5.616-15,14.538s6.977,11.04,13.383,12.56c3.676.905,6.751,1.593,6.925,4.875.168,3.2-2.041,4.586-5.154,4.75-3.812.2-8.629-1.5-11.738-3.094l-3.493,8.036c2.459,1.646,8.8,4,16.27,3.605,9.755-.51,16.141-5.65,15.645-15.169-.462-8.758-7.469-10.674-13.71-12.291-3.85-.976-6.685-1.929-6.858-5.211-.144-2.782,2.069-4,4.851-4.144,2.878-.149,5.977,1.112,9.269,3.417Z"
          />
          <path
            fill="#ec6602"
            d="M186.174,103.533h11.044V94.341H186.174V81.021H174.456V94.346h-6.5v9.192h6.5v20.818c0,7.084.674,13.994,12.815,13.994a22.35,22.35,0,0,0,10.708-2.695l-2.532-7.844a14.517,14.517,0,0,1-5.144,1.353c-3.118,0-4.129-1.858-4.129-6.492Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoSiemens;
