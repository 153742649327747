import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useAppContext } from "../contexts/AppContext";

export default function SelectEnvironment() {
  const [environment, setEnvironment] = React.useState("");
  const [demos, setDemos] = React.useState<any[]>([]);
  const { settings, setActiveEnvironment } = useAppContext();

  useEffect(() => {
    if (settings?.demos) {
      setDemos(settings?.demos);
    }
  }, [settings]);

  const handleChange = (event: SelectChangeEvent) => {
    setEnvironment(event.target.value);
    if (setActiveEnvironment) setActiveEnvironment(event.target.value);
  };

  const renderList = () => {
    const unique = [...new Set(demos.map((item) => item.environment))];
    return unique.map((demo) => {
      return (
        <MenuItem key={demo} value={demo}>
          {demo}
        </MenuItem>
      );
    });
  };

  const { classes } = useStyles();

  return (
    <FormControl
      sx={{ minWidth: "100%" }}
      size="small"
      classes={{
        root: environment ? classes.rootActive : classes.rootInactive,
      }}
    >
      <InputLabel id="demo-select-small"> Environment</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={environment}
        label="Environment"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {renderList()}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles()(() => ({
  rootActive: {
    background: "#f6f9fc",
  },
  rootInactive: {
    background: "transparent",
  },
}));
