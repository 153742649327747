import * as React from "react";

function SvgLogoSiemens(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 306 306"
      width="100"
      height="20px"
    >
      <defs></defs>
      <g id="Layer_1" data-name="Layer 1">
        <rect
          fill="#232222"
          x="4.5"
          y="4.5"
          width="297"
          height="297"
          rx="148.5"
        />
        <path
          fill="#7f7f7f"
          d="M156.83,175.27c-1-.93-.92-2.24-1.29-3.38A55,55,0,0,0,127,138.78a3.13,3.13,0,0,0-3.16,0c-15,7.59-24.67,19.5-29.23,35.63-.15.52,0,1.17-.59,1.52a3.85,3.85,0,0,1-.28-.85c-.72-6.67-4.19-11.29-10.31-14-2.38-1.07-4.94-1-7.41-1.51,0-.69.67-.68,1.07-.87q23.85-11.18,30.93-36.53c.1-.35.09-.76.56-.88.17.59.39,1.18.5,1.78a16.62,16.62,0,0,0,32.58.81c.26-1.09,0-2.25.58-3.28,1,2.89,1.82,5.83,3,8.64a54.87,54.87,0,0,0,27,28.8,2.78,2.78,0,0,0,2.79,0q22-11.09,29.1-34.63c.17-.58,0-1.43.84-1.7.47.33.44.86.53,1.35a16.53,16.53,0,0,0,13.17,13.5c1.68.36,3.37,0,5,.45-.14.43-.54.47-.87.62q-24.87,11.22-32,37.56c-.08.3-.09.62-.41.79-.35-.27-.28-.67-.32-1a16.6,16.6,0,0,0-15.6-15.07c-7.66-.58-15.07,4.88-17.05,12.55C157.31,173.35,157.48,174.42,156.83,175.27Z"
        />
        <path
          fill="#fff"
          d="M76.06,159.58c2.47.5,5,.44,7.41,1.51,6.12,2.72,9.59,7.34,10.31,14a3.85,3.85,0,0,0,.28.85c-.5,7.68-3.88,13.43-11.31,16.17a16.62,16.62,0,1,1-10.5-31.54C73.5,160.18,74.79,159.91,76.06,159.58Z"
        />
        <path
          fill="#fff"
          d="M156.83,175.27c.65-.85.48-1.92.73-2.87,2-7.67,9.39-13.13,17.05-12.55a16.6,16.6,0,0,1,15.6,15.07c0,.37,0,.77.32,1-.39,6.47-2.93,11.73-8.73,15-10.45,5.93-23.45-1-24.74-13.08C157,177,156.91,176.14,156.83,175.27Z"
        />
        <path
          fill="#fff"
          d="M142.28,120.61c-.56,1-.32,2.19-.58,3.28a16.62,16.62,0,0,1-32.58-.81c-.11-.6-.33-1.19-.5-1.78.25-6,2.26-11.14,7.37-14.69a16.62,16.62,0,0,1,26,11.59C142.07,119,142.18,119.81,142.28,120.61Z"
        />
        <path
          fill="#fff"
          d="M223.77,137c-1.65-.42-3.34-.09-5-.45A16.53,16.53,0,0,1,205.58,123c-.09-.49-.06-1-.53-1.35.2-5.77,1.87-10.93,6.66-14.52,5.69-4.27,11.93-4.89,18.17-1.46,6.4,3.51,9.3,9.22,8.49,16.48-.78,6.94-4.85,11.48-11.35,13.88C226,136.46,224.85,136.69,223.77,137Z"
        />
      </g>
    </svg>
  );
}

export default SvgLogoSiemens;
