import React, { FC, ReactElement } from "react";
import { Box, IconButton } from "@mui/material";
import SvgLogo from "../images/SvgLogo";

const Navbar: FC = (): ReactElement => {
  const handleLogoClick = () => {
    window.open("https://databridge.ch", "_blank");
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "45px",
        backgroundColor: "#FFF",
        borderBottom: "1px solid #DDE4E3",
        paddingTop: 0.3,
        paddingBottom: 0.5,
        paddingLeft: "20px",
      }}
    >
      <IconButton
        onClick={handleLogoClick}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <SvgLogo />
      </IconButton>
    </Box>
  );
};

export default Navbar;
