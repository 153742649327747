import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";

import SvgLogoMerck from "../images/SvgLogoMerck";
import SvgLogoSiemens from "../images/SvgLogoSiemens";
import SvgLogoZurich from "../images/SvgLogoZurich";
import SvgLogoNotitia from "../images/SvgLogoNotitia";

export default function CarouselCustomer() {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const images = [
    {
      component: (
        <Box
          key={new Date().getMilliseconds()}
          display="flex"
          width="100%"
          alignItems="center"
          pt={4}
          sx={{ border: "1px solid balck" }}
        >
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoZurich />
          </Box>
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoMerck />
          </Box>
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoSiemens />
          </Box>
        </Box>
      ),
    },
    {
      component: (
        <Box
          key={new Date().getMilliseconds()}
          display="flex"
          width="100%"
          pt={4}
          alignItems="center"
        >
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoMerck />
          </Box>
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoSiemens />
          </Box>
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoNotitia />
          </Box>
        </Box>
      ),
    },
    {
      component: (
        <Box
          key={new Date().getMilliseconds()}
          display="flex"
          width="100%"
          pt={4}
          alignItems="center"
        >
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoSiemens />
          </Box>
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoNotitia />
          </Box>
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoZurich />
          </Box>
        </Box>
      ),
    },
    {
      component: (
        <Box
          key={new Date().getMilliseconds()}
          display="flex"
          width="100%"
          pt={4}
          alignItems="center"
        >
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoNotitia />
          </Box>
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoZurich />
          </Box>
          <Box textAlign="center" flexGrow={1}>
            <SvgLogoMerck />
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Carousel
      autoPlay={true}
      indicators={false}
      next={(next, active) => setActiveIndex(active || 0 + 1)}
      prev={(prev, active) => setActiveIndex(active || 0 - 1)}
      index={activeIndex}
    >
      {
        // eslint-disable-next-line array-callback-return
        images.map((image, index) => {
          if (index <= activeIndex + 2) {
            return image.component;
          }
        })
      }
    </Carousel>
  );
}
