import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import ReactPlayer from "react-player";
import { useTheme, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import Cube3D from "./Cube3D";
import SvgGuidedTour from "../images/SvgGuidedTour";
import SvgSelfService from "../images/SvgSelfService";
import Vid from "../video/analytics.mp4";
import SvgManagementConsole from "../images/SvgManagementConsole";
import { useAppContext } from "../contexts/AppContext";

function Stepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState<any[]>([]);
  const { settings } = useAppContext();

  const images = [
    {
      step: 0,
      component: (
        <ReactPlayer
          url={Vid}
          playing={true}
          controls={false}
          loop={true}
          muted={true}
          playsinline={true}
          width="500px"
          height="320px"
          style={{
            borderRadius: "8px",
            background: "#f7f5f7",
            padding: 2,
            boxShadow:
              "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
          }}
        />
      ),
    },
    {
      step: 1,
      component: (
        <Box
          sx={{
            marginTop: "-28px",
            marginBottom: "0px",
            boxShadow:
              "rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
          }}
        >
          <SvgSelfService />
        </Box>
      ),
    },
    {
      step: 1,
      component: <Cube3D />,
    },
    {
      step: 2,
      component: <SvgManagementConsole />,
    },
    {
      step: 3,
      component: (
        <Box
          sx={{
            padding: "0",
            marginTop: "-10px",
            borderRadius: "8px",
            background: "white",
          }}
        >
          <SvgGuidedTour />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (settings?.steps) {
      setSteps(settings?.steps);
    }
  }, [settings]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { classes } = useStyles();

  if (steps?.length === 0) return null;

  return (
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box
        height="450px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          mb={activeStep === 1 ? 5 : 10}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {images[activeStep].component}
        </Box>
      </Box>
      <Box textAlign="center" mb={2} mt={2} pl={4} pr={4}>
        <Typography
          sx={{
            color: "primary.contrastText",
            fontSize: "1.25rem",
          }}
        >
          {steps[activeStep].title}
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography
          sx={{
            color: "primary.contrastText",
            fontSize: "0.825rem",
            opacity: 0.775,
            padding: 2,
          }}
        >
          {steps[activeStep].description}
        </Typography>
      </Box>
      <MobileStepper
        sx={{ background: "transparent" }}
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        classes={{
          dotActive: classes.dotActive,
          dot: classes.dot,
        }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
            color="secondary"
            classes={{
              disabled: classes.disabled,
            }}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            color="secondary"
            classes={{
              disabled: classes.disabled,
            }}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />{" "}
    </Box>
  );
}

export default Stepper;

const useStyles = makeStyles()((theme: Theme) => ({
  dot: {
    background: "#dfe9f4",
  },
  dotActive: {
    background: theme.palette.secondary.main,
  },
  disabled: {
    color: "#FFF !important",
  },
}));
