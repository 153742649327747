// src/App.tsx

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Grid, Box, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Stepper from "./components/Stepper";
import AppProvider from "./contexts/AppProvider";

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<any>(null);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 500px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });

  // define theme
  const theme = createTheme({
    typography: {
      fontFamily: ["Roboto"].join(","),
    },
    palette: {
      primary: {
        light: "#63b8ff",
        main: "#0989e3",
        dark: "#005db0",
        contrastText: "#fff",
      },
      secondary: {
        main: "#F1C232",
        light: "#82e9de",
        dark: "#00867d",
        contrastText: "#fff",
      },
      text: {
        primary: "#092C4F",
        secondary: "#8294A6",
      },
    },
  });

  useEffect(() => {
    if (process.env.REACT_APP_CONFIGURATION_ENDPOINT) {
      fetch(process.env.REACT_APP_CONFIGURATION_ENDPOINT)
        .then((response) => response.json())
        .then((jsonData) => {
          setSettings(jsonData);
        })
        .catch((error) => {
          setSettings({});
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading) return null;

  return (
    <AppProvider settings={settings}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={6} xl={6}>
            <Box height="100vh" display="flex" flexDirection="column">
              <Router>
                <Navbar />
                <Routes>
                  {appRoutes.map((route) => (
                    <Route
                      key={route.key}
                      path={route.path}
                      element={<route.component />}
                    />
                  ))}
                </Routes>
                <Footer />
              </Router>
            </Box>
          </Grid>

          {(isBigScreen || isDesktopOrLaptop) && (
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <Box
                sx={{
                  height: "100vh",
                  backgroundImage: `linear-gradient(19deg, #0F4B87 0%, #092C4F 100%)`,
                  display: { xs: "none", md: "none", lg: "block", xl: "block" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stepper />
              </Box>
            </Grid>
          )}
        </Grid>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
