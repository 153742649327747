import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SelectEnvironment from "./SelectEnvironment";
import SelectApp from "./SelectApp";
import Submit from "./Submit";
import CarouselCustomer from "./CarouselCustomer";
import SvgLogoQPlus from "../images/SvgLogoQPlus";
import { useAppContext } from "../contexts/AppContext";

function Login() {
  const [title, setTitle] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [select1Text, setSelect1Text] = useState<string>("");
  const [select2Text, setSelect2Text] = useState<string>("");
  const { settings, activeEnvironment } = useAppContext();

  useEffect(() => {
    if (settings) {
      setTitle(settings?.title);
      setSubTitle(settings?.subTitle);
      setSelect1Text(settings?.select1);
      setSelect2Text(settings?.select2);
    }
  }, [settings]);

  const handleLogoClick = () => {
    window.open("https://insight.databridge.ch", "_blank");
  };

  const { classes } = useStyles();

  return (
    <Box className={classes.box}>
      <Box pl={2} pt={2}>
        <IconButton
          onClick={handleLogoClick}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <SvgLogoQPlus />
        </IconButton>
      </Box>
      <Box p={4}>
        <Box mb={2} pl={4} pr={4}>
          <Typography
            sx={{
              color: "text.main",
              fontSize: "1.5rem",
              fontWeight: 500,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box mt={2} pl={4} pr={4}>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "0.825rem",
              fontWeight: 400,
            }}
          >
            {subTitle}
          </Typography>
        </Box>
        <Box mt={3} pl={4} pr={4}>
          <Typography
            sx={{
              color: "text.primary",
              fontSize: "0.825rem",
              fontWeight: 500,
              mb: "12px",
            }}
          >
            {select1Text}
          </Typography>
          <SelectEnvironment />
        </Box>
        <Box mt={4} pl={4} pr={4}>
          <Typography
            sx={{
              color: "text.primary",
              fontSize: "0.825rem",
              fontWeight: 500,
              mb: "12px",
            }}
          >
            {select2Text}
          </Typography>
          <SelectApp />
          {activeEnvironment === "SaaS" && (
            <Box>
              <Typography
                sx={{
                  color: "text.primary",
                  fontSize: "0.625rem",
                  fontWeight: 400,
                  opacity: 0.7,
                  mt: "12px",
                }}
              >
                Note 3 user logins available on SaaS (userId and pwd are the
                same): <br />
                <ul>
                  <li>demo1@databridge.ch</li>
                  <li>demo2@databridge.ch</li>
                  <li>demo3@databridge.ch</li>
                </ul>
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          mt={activeEnvironment === "SaaS" ? 1 : 7}
          textAlign="right"
          pl={4}
          pr={4}
        >
          <Submit />
        </Box>
        <Box mt={2}>
          <CarouselCustomer />
        </Box>
      </Box>
    </Box>
  );
}

export default Login;

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    border: "1px solid #E5E5E5",
    boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.04) !important",
    borderRadius: "8px",
    minHeight: "450px",
    background: "white",
    width: "600px",
  },
}));
